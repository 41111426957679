import React from "react";
import { Google, LinkedIn, Twitter } from "../../../components/Icons/Icons";
import { Container, Col, Row } from "reactstrap";
import Socials from "../../../components/Socials/Socials";
import SimpleForm from "./SimpleForm/SimpleForm";
import Frame from "../../../components/Frame/Frame";
import classes from "./CactusContactSection.module.css"
import { Fade } from "react-awesome-reveal";
import metaballIcon from "../../../img/metaball_principal_alpha.png"

const title = "Want to talk to a human?";
const description = "Please reach out to us if you have any questions or would like to schedule a demo.";
const email = "info@gocactus.io";

function CactusContactSection(props) {

    return (
        <React.Fragment>
            <section className={`section ${classes.footer}${(props.dark) ? ` dark ${classes.dark}` : ''}`} id="contact" name="contact">
                <Fade triggerOnce={true}>
                    <img
                        src={metaballIcon}
                        alt=""
                        className={`${classes.bgImg} position-absolute`}
                    />
                </Fade>
                <div className={classes.gridWrapper}>
                    <Container>
                        <Row>
                            <Col lg={7} md={7} className={classes.leftColumn}>
                                <Fade triggerOnce={true}>
                                    <h1>{title}</h1>
                                    <p className={classes.description}>{description}</p>
                                    <SimpleForm
                                        dark={props.dark ? true : false}
                                    />
                                </Fade>
                            </Col>

                            <Col lg={5} md={5} className={classes.rightColumn}>
                                <Fade top triggerOnce={true} delay={50}>
                                    <div className={classes.contacts}>
                                        <div className={classes.item}>
                                            <div className={classes.label}>Phone</div>
                                            <a href={`mailto:${email}`} className={classes.target}>{email}</a>
                                        </div>
                                    </div>
                                    <Socials dark={props.dark ? true : false}>
                                        <a href={"./example"}>
                                            <Frame size="48" color="#EFF2F6">
                                                <Twitter />
                                            </Frame>
                                        </a>
                                        <a href={"./example"}>
                                            <Frame size="48" color="#EFF2F6">
                                                <Google />
                                            </Frame>
                                        </a>
                                        <a href={"./example"}>
                                            <Frame size="48" color="#EFF2F6">
                                                <LinkedIn />
                                            </Frame>
                                        </a>
                                    </Socials>
                                </Fade>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    )
}
export default CactusContactSection
