import React from "react";
import classes from "./CactusStaffAppSection.module.css"
import Content from "./Content/CTATypeFirst";
import iphoneMockup from "../../../img/IphoneXS.png"
import metaballIconStaff from "../../../img/metaball_staff_alpha.png"
import { Container, Col, Row } from "reactstrap";
import { Fade } from "react-awesome-reveal";

const title = "Update your menu on the fly and give your customers a live view";
const description = "From Cactus Staff you'll be able to customize your restaurant profile and edit your products anytime to accommodate for real life.";

function CactusStaffAppSection(props) {

    return (
        <React.Fragment>
            <section className={`section ${classes.cta}${(props.dark) ? ` dark ${classes.dark}` : ''}`} id="staff" name="staff">
                <Fade triggerOnce={true}>
                    <img
                        src={metaballIconStaff}
                        alt=""
                        className={`${classes.bgImg} ${classes.bgImgLeft} position-absolute`}
                    />
                </Fade>
                <Fade triggerOnce={true}>
                    <img
                        src={metaballIconStaff}
                        alt=""
                        className={`${classes.bgImg} ${classes.bgImgRight} position-absolute`}
                    />
                </Fade>
                <Container>
                    <Row className={classes.contentRow}>
                        <Col lg={5} className={classes.leftColumn}>
                            <Fade left triggerOnce={true}>
                                <img
                                    src={iphoneMockup}
                                    alt=""
                                />
                            </Fade>
                        </Col>
                        <Col lg={7} className={classes.rightColumn}>
                            <Content
                                title={title}
                                description={description}
                            >
                            </Content>
                        </Col>
                    </Row>
                </Container>
            </section>
        </React.Fragment>
    )
}
export default CactusStaffAppSection