import React from "react";
import { Container, Col, Row } from "reactstrap";
import placeholder from '../../../img/placeholder.png';
import Header from "./Header/Header";
import classes from "./CactusHeroSection.module.css"
import { Fade } from "react-awesome-reveal";
import { Apple, GooglePlay } from "../../../components/Icons/Icons";

const titleText = 'Easy. Menu management.';
const descriptionText = 'Your no-touch QR menu. Updated live. To your customers\' fingertips.'

function CactusHeroSection(props) {
    return (
        <React.Fragment>
            <section className={`${classes.hero}${(props.dark) ? ` dark ${classes.dark}` : ''}`} id="home" name="home">
                <div className={classes.gridWrapper}>
                    <Container className={classes.headerContainer}>
                        <Fade top triggerOnce={true}>
                            <Header
                                dark={props.dark ? true : false}
                            />
                        </Fade>
                    </Container>
                    <Container>
                        <Row className={classes.contentRow}>
                            <Col lg="5" className={classes.rightColumn}>
                                <Fade top delay={100} triggerOnce={true}>
                                    <h1 >{titleText}</h1>
                                </Fade>
                                <Fade top delay={200} triggerOnce={true}>
                                    <p className={`normal-text ${classes.description}`}>{descriptionText}</p>
                                </Fade>
                                <div className={classes.buttonRow}>
                                    <Fade top delay={150} triggerOnce={true}>
                                        <a className={classes.circleIcon} href="#">
                                            <Apple fill="#FFFF" />
                                        </a>
                                    </Fade>
                                    <Fade top delay={150} triggerOnce={true}>
                                        <a className={classes.circleIcon} href="#">
                                            <GooglePlay fill="#FFFF" />
                                        </a>
                                    </Fade>

                                </div>
                            </Col>

                            <Col lg="7" className={classes.leftColumn}>
                                <Fade right delay={50} triggerOnce={true}>
                                    <img className={classes.placeholder} src={placeholder} alt="" />
                                </Fade>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    )
}
export default CactusHeroSection